import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import firebase from "gatsby-plugin-firebase"

import imgP from "../../assets/image/svg/profile-color.svg";

const ModalStyled = styled(Modal)`
  /* &.modal {
    z-index: 10050;
  } */
  .modal-dialog {
    margin: 1.75rem auto;
    max-width: 100%;
  }
  .modal-content {
    background: transparent;
  }
`;

const ModalCustomerApplicant = (props) => {
    //console.log(props);

    function handleClose() {
        props.setModalCustomerApplicantVisible(false)
    }

    function handleCreateAccount() {
        let originalUser = firebase.auth().currentUser;
        firebase.auth().fetchSignInMethodsForEmail(props.customerdata.Email)
            .then(async (signInMethods) => {
                if (signInMethods.length) {
                    alert("email exists")
                } else {
                    try {
                        const result = await firebase
                            .auth()
                            .createUserWithEmailAndPassword(props.customerdata.Email, "@Ecto321");
                        firebase.auth().currentUser.updateProfile({ displayName: "Customer"});
                        firebase.auth().updateCurrentUser(originalUser);
                        firebase.auth().sendPasswordResetEmail(props.customerdata.Email)
                            .then(async function () {
                                alert('Verify your email & Reset your password to finish your signing up');
                                // TBD: set status as accepted
                                firebase
                                    .firestore()
                                    .collection('customer-contacts')
                                    .doc(props.customerdata.id)
                                    .update({ "status": "accepted" });

                                // add customerdata to customers database with today's date
                                props.customerdata["created"] = firebase.firestore.Timestamp.now();
                                firebase
                                    .firestore()
                                    .collection('customers')
                                    .add(props.customerdata)
                                    .then(() => {
                                        console.log(props.customerdata.Email + " successfully saved in firestore")
                                    })
                                    .catch((error) => {
                                        console.error("Error adding document to customers: ", error);
                                    });
                            })
                            .catch(function (error) {
                                // Error occurred. Inspect error.code.
                            });
                    } catch (err) {
                        alert(err.message)
                    }
                }
                handleClose();
            })
            .catch((error) => {
                alert("error");
                console.log(error)
            });
    }

    function handleReject() {
        firebase
            .firestore()
            .collection('customer-contacts')
            .doc(props.customerdata.id)
            .update({ "status": "rejected" });
        handleClose();
    }

    return (
        <ModalStyled show={props.show} centered size="lg">
            <Modal.Body className="p-0">
                <div className="container position-relative">
                    <button
                        type="button"
                        className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                        onClick={handleClose}
                    >
                        <i className="fas fa-times"></i>
                    </button>
                    <div className="login-modal-main bg-white rounded-8 overflow-hidden">
                        <div className="row no-gutters">
                            {/* <!-- Left Sidebar Start --> */}
                            <div className="col-12 col-xl-3 col-lg-4 col-md-5 mb-13 mb-lg-0 border-right border-mercury">

                                <div {...props}>
                                    <div className="pl-lg-5">
                                        {/* <!-- Top Start --> */}
                                        <div className="bg-white shadow-9 rounded-4">
                                            <div className="px-5 py-11 text-center border-bottom border-mercury">
                                                <Link to="/#" className="mb-4">
                                                    <img className="circle-54" src={imgP} alt="" />
                                                </Link>
                                                <h4 className="mb-0">
                                                    <Link
                                                        to="/#"
                                                        className="text-black-2 font-size-6 font-weight-semibold"
                                                    >
                                                        {props.customerdata.YourName}
                                                    </Link>
                                                </h4>
                                                <p className="mb-8">
                                                    <Link to="/#" className="text-gray font-size-4">
                                                        {props.customerdata.Designation}
                                                    </Link>
                                                </p>
                                            </div>
                                            {/* <!-- Top End --> */}
                                            {/* <!-- Bottom Start --> */}
                                            <div className="px-9 pt-lg-5 pt-9 pt-xl-9 pb-5">
                                                <h5 className="text-black-2 mb-8 font-size-5">Contact Info</h5>
                                                {/* <!-- Single List --> */}
                                                <div className="mb-7">
                                                    <p className="font-size-4 mb-0">E-mail</p>
                                                    <h5 className="font-size-4 font-weight-semibold mb-0">
                                                        <a
                                                            className="text-black-2 text-break"
                                                            href="mailto:name_ac@gmail.com"
                                                        >
                                                            {props.customerdata.Email}
                                                        </a>
                                                    </h5>
                                                </div>
                                                {/* <!-- Single List --> */}
                                                {/* <!-- Single List --> */}
                                                <div className="mb-7">
                                                    <p className="font-size-4 mb-0">Phone</p>
                                                    <h5 className="font-size-4 font-weight-semibold mb-0">
                                                        <a className="text-black-2 text-break" href="tel:+999565562">
                                                            {props.customerdata.YourIsd} - {props.customerdata.YourPhone}
                                                        </a>
                                                    </h5>
                                                </div>
                                                {/* <!-- Single List --> */}
                                            </div>
                                            {/* <!-- Bottom End --> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <!-- Left Sidebar End --> */}
                            {/* <!-- Middle Content --> */}
                            <div className="col-12 col-xl-6 col-lg-8 col-md-7 order-2 order-lg-1 border-right border-mercury">
                                <div className="bg-white rounded-4 overflow-auto ">
                                    {/* <!-- Excerpt Start --> */}
                                    <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-3">
                                        <h4 className="font-size-6 font-weight-semibold mb-3 mt-5 text-black-2">
                                            Organization
                                        </h4>
                                        <p className="font-size-4 font-weight-semibold mb-4">
                                            {props.customerdata.Organization}
                                        </p>
                                    </div>

                                    {/* <!-- Single List --> */}
                                    {
                                        <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-3">
                                        <h4 className="font-size-4 font-weight-semibold mb-3 mt-5 text-black-2">
                                            Website
                                        </h4>
                                            <h5 className="font-size-4 font-weight-semibold mb-0">
                                                <a href={"http://" + props.customerdata.Website} target="_blank" className="text-break">
                                                    {props.customerdata.Website}
                                                </a>
                                            </h5>
                                        </div>
                                    }
                                    {/* <!-- Single List --> */}
                                    <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-3">
                                        <h4 className="font-size-4 font-weight-semibold mb-3 mt-5 text-black-2">
                                            Address
                                        </h4>
                                        <p className="font-size-4 mb-4">
                                            {props.customerdata.Address}
                                        </p>
                                        <p className="font-size-4 mb-4">
                                            {props.customerdata.Street}
                                        </p>
                                        <p className="font-size-4 mb-4">
                                            {props.customerdata.City}
                                        </p>
                                        <p className="font-size-4 mb-4">
                                            {props.customerdata.Country}
                                        </p>
                                    </div>

                                    <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-3">
                                        <h4 className="font-size-4 font-weight-semibold mb-3 mt-5 text-black-2">
                                            Organization Phone
                                        </h4>
                                        <p className="font-size-4 mb-4">
                                            {props.customerdata.OrgIsd} - {props.customerdata.OrgPhone}
                                        </p>
                                    </div>
                                    <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-3">
                                        <h4 className="font-size-4 font-weight-semibold mb-3 mt-5 text-black-2">
                                            Subject
                                        </h4>
                                        <p className="font-size-4 mb-4">
                                            {props.customerdata.Subject}
                                        </p>
                                    </div>
                                    <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-3 pb-5">
                                        <h4 className="font-size-4 font-weight-semibold mb-3 mt-5 text-black-2">
                                            Message
                                        </h4>
                                        <p className="font-size-4 mb-8">
                                            {props.customerdata.Message}
                                        </p>
                                    </div>
                                    {/* <!-- Excerpt End --> */}
                                    {/* <!-- Card Section End --> */}
                                </div>
                            </div>
                            {/* <!-- Middle Content --> */}
                            {/* <!-- Right Sidebar Start --> */}
                            <div className="col-12 col-xl-3 order-3 order-lg-2 bg-default-2">
                                <div className="text-center mb-13 mb-lg-0 mt-12">
                                    <button
                                        className="btn btn-primary btn-xl mb-7 d-block mx-auto text-uppercase"
                                        onClick={handleCreateAccount}
                                    >
                                        Create Account
                                    </button>
                                    <button
                                        className="btn btn-outline-gray btn-xl mb-7 d-block mx-auto text-uppercase"
                                        onClick={handleReject}
                                    >
                                        Reject
                                    </button>
                                    <button
                                        className="btn btn-outline-gray btn-xl mb-7 d-block mx-auto text-uppercase"
                                        onClick={handleClose}
                                    >
                                        Ignore
                                    </button>
                                </div>
                            </div>
                            {/* <!-- Right Sidebar End --> */}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </ModalStyled>
    );
};

export default ModalCustomerApplicant;
