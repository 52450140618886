import React, { useContext, useState } from "react";
import { navigate } from "gatsby"
import firebase from "gatsby-plugin-firebase";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";

import PageWrapper from "../components/PageWrapper";
import GlobalContext from "../context/GlobalContext";
import ModalCustomerApplicant from "../components/ModalCustomerApplicant";
import ModalAffiliateApplicant from "../components/ModalAffiliateApplicant";

const DashboardAdmin = () => {
  const gContext = useContext(GlobalContext);
  //console.log(gContext.user)
  if (typeof window !== `undefined`) {
    if (gContext.user !== null && gContext.user !== undefined) {
      if (gContext.user.isAnonymous !== true) {
        if (gContext.user.email !== "info@e-cto.com")
          navigate("/");
      }
    }
  }

  const [modalCustomerApplicantVisible, setModalCustomerApplicantVisible] = useState(false);
  const [modalAffiliateApplicantVisible, setModalAffiliateApplicantVisible] = useState(false);
  const [customerdata, setCustomerdata] = useState({});
  const [affiliatedata, setAffiliatedata] = useState({});
  const [stats, setStats] = useState({
    done: false,
    projectsCount: 0,
    projectsApplicants: 0,
    affiliateApplicantsSize: 0,
    affiliateApplicants: [],
    customerApplicantsSize: 0,
    customerApplicants: [],
    affiliatesSize: 0,
    affiliates: [],
    customersSize: 0,
    custoemrs: []
  });

  function toggleModalCustomerApplicantVisibility() {
    setModalCustomerApplicantVisible(!modalCustomerApplicantVisible);
  }

  function toggleModalAffiliateApplicantVisibility() {
    setModalAffiliateApplicantVisible(!modalAffiliateApplicantVisible);
  }

  function getStats() {
    let tempStats = {};
    let promise1, promise2, promise3, promise4, promise5, promise6;

    promise1 = firebase
      .firestore()
      .collection('projects_posted')
      .get()
      .then(function (querySnapshot) {
        tempStats["projectsCount"] = querySnapshot.size;
      });

    promise2 = firebase
      .firestore()
      .collection('projects_applicants')
      .get()
      .then(function (querySnapshot) {
        tempStats["projectsApplicants"] = querySnapshot.size;
      });

    promise3 = firebase
      .firestore()
      .collection('affiliate-contacts')
      .get()
      .then(function (querySnapshot) {
        tempStats["affiliateApplicantsSize"] = querySnapshot.size;
        tempStats["affiliateApplicants"] = querySnapshot.docs.map(doc => {
          let obj = doc.data();
          obj.id = doc.id;
          return obj;
        });
      });

    promise4 = firebase
      .firestore()
      .collection('customer-contacts')
      .get()
      .then(function (querySnapshot) {
        tempStats["customerApplicantsSize"] = querySnapshot.size;
        tempStats["customerApplicants"] = querySnapshot.docs.map(doc => {
          let obj = doc.data();
          obj.id = doc.id;
          return obj;
        });
      });

    promise5 = firebase
      .firestore()
      .collection('affiliates')
      .get()
      .then(function (querySnapshot) {
        tempStats["affiliatesSize"] = querySnapshot.size;
        tempStats["affiliates"] = querySnapshot.docs.map(doc => {
          let obj = doc.data();
          obj.id = doc.id;
          return obj;
        });
      });

    promise6 = firebase
      .firestore()
      .collection('customers')
      .get()
      .then(function (querySnapshot) {
        tempStats["customersSize"] = querySnapshot.size;
        tempStats["customers"] = querySnapshot.docs.map(doc => {
          let obj = doc.data();
          obj.id = doc.id;
          return obj;
        });
      });

    Promise.all([promise1, promise2, promise3, promise4, promise5, promise6])
      .then(() => {
        //console.log(tempStats);
        setStats(tempStats);
      });
  }

  React.useEffect(() => {
    getStats();
  }, []);

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-25 mt-lg-31">
          <div className="container">
            <div className="row mb-7">
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                {/* <!-- Single Category --> */}
                <a
                  href="/#"
                  className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                >
                  <div className="text-blue bg-blue-opacity-1 circle-56 font-size-6 mr-7">
                    <i className="fas fa-briefcase"></i>
                  </div>
                  {/* <!-- Category Content --> */}
                  <div className="">
                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                      <LazyLoad>
                        <span className="counter">
                          <CountUp duration={1} end={stats.projectsCount} />
                        </span>
                      </LazyLoad>
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                      Posted Projects
                    </p>
                  </div>
                </a>
                {/* <!-- End Single Category --> */}
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                {/* <!-- Single Category --> */}
                <a
                  href="/#"
                  className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                >
                  <div className="text-orange bg-orange-opacity-1 circle-56 font-size-6 mr-7">
                    <i className="fas fa-eye"></i>
                  </div>
                  {/* <!-- Category Content --> */}
                  <div className="">
                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                      <LazyLoad>
                        <span className="counter">
                          <CountUp
                            duration={3}
                            end={stats.customersSize}
                          />
                        </span>
                      </LazyLoad>
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                      Total Customers
                    </p>
                  </div>
                </a>
                {/* <!-- End Single Category --> */}
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                {/* <!-- Single Category --> */}
                <a
                  href="/#"
                  className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                >
                  <div className="text-pink bg-pink-opacity-1 circle-56 font-size-6 mr-7">
                    <i className="fas fa-user"></i>
                  </div>
                  {/* <!-- Category Content --> */}
                  <div className="">
                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                      <LazyLoad>
                        <span className="counter">
                          <CountUp duration={1} end={stats.affiliatesSize} />
                        </span>
                      </LazyLoad>
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                      Total Affiliates
                    </p>
                  </div>
                </a>
                {/* <!-- End Single Category --> */}
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                {/* <!-- Single Category --> */}
                <a
                  href="/#"
                  className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                >
                  <div className="text-blue bg-blue-opacity-1 circle-56 font-size-6 mr-7">
                    <i className="fas fa-briefcase"></i>
                  </div>
                  {/* <!-- Category Content --> */}
                  <div className="">
                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                      <LazyLoad>
                        <span className="counter">
                          <CountUp duration={1} end={stats.projectsApplicants} />
                        </span>
                      </LazyLoad>
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                      Project Applicants
                    </p>
                  </div>
                </a>
                {/* <!-- End Single Category --> */}
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                {/* <!-- Single Category --> */}
                <a
                  href="/#"
                  className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                >
                  <div className="text-orange bg-orange-opacity-1 circle-56 font-size-6 mr-7">
                    <i className="fas fa-eye"></i>
                  </div>
                  {/* <!-- Category Content --> */}
                  <div className="">
                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                      <LazyLoad>
                        <span className="counter">
                          <CountUp
                            duration={1}
                            end={stats.customerApplicantsSize}
                          />
                        </span>
                      </LazyLoad>
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                      Customer Applicants
                    </p>
                  </div>
                </a>
                {/* <!-- End Single Category --> */}
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                {/* <!-- Single Category --> */}
                <a
                  href="/#"
                  className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                >
                  <div className="text-pink bg-pink-opacity-1 circle-56 font-size-6 mr-7">
                    <i className="fas fa-user"></i>
                  </div>
                  {/* <!-- Category Content --> */}
                  <div className="">
                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                      <LazyLoad>
                        <span className="counter">
                          <CountUp duration={1} end={stats.affiliateApplicantsSize} />
                        </span>
                      </LazyLoad>
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                      Affiliate Applicants
                    </p>
                  </div>
                </a>
                {/* <!-- End Single Category --> */}
              </div>
            </div>

            <div className="mb-14">
              <div className="row mb-11 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">Customer Applicants List ( {stats.customerApplicantsSize} )</h3>
                </div>
              </div>
              <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="pl-0  border-0 font-size-4 font-weight-normal"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-normal"
                        >
                          Organization
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-normal"
                        >
                          Applied on
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-normal"
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        stats.customerApplicants.map((doc, i) => {
                          return (
                            <tr key={i} className="border border-color-2">
                              <th scope="row" className="pl-6 border-0 py-7 pr-0">
                                <h3 className="font-size-4 mb-0 font-weight-normal text-black-2">
                                  {doc.YourName}
                                </h3>
                              </th>
                              <td className="table-y-middle py-7 min-width-px-235 pr-0">
                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                  {doc.Organization}
                                </h3>
                              </td>
                              <td className="table-y-middle py-7 min-width-px-170 pr-0">
                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                  {new Date(doc.created.seconds * 1000 + doc.created.nanoseconds / 1000000).toDateString()}
                                </h3>
                              </td>
                              <td className="table-y-middle py-7 min-width-px-235 pr-0">
                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                  {doc.status}
                                </h3>
                              </td>
                              {
                                (doc.status === "applied") &&
                                <td className="table-y-middle py-7 min-width-px-170 pr-0">
                                  <div className="">
                                    <a
                                      href="/#"
                                      className="font-size-3 font-weight-bold text-black-2 text-uppercase"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        toggleModalCustomerApplicantVisibility();
                                        setCustomerdata(doc);
                                      }}
                                    >
                                      View Application
                                    </a>
                                  </div>
                                </td>
                              }
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="mb-14">
              <div className="row mb-11 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">Affiliate Applicants List ( {stats.affiliateApplicantsSize} )</h3>
                </div>
              </div>
              <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="pl-0  border-0 font-size-4 font-weight-normal"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-normal"
                        >
                          Organization
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-normal"
                        >
                          Applied on
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-normal"
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        stats.affiliateApplicants.map((doc, i) => {
                          return (
                            <tr key={i} className="border border-color-2">
                              <th scope="row" className="pl-6 border-0 py-7 pr-0">
                                <h3 className="font-size-4 mb-0 font-weight-normal text-black-2">
                                  {doc.YourName}
                                </h3>
                              </th>
                              <td className="table-y-middle py-7 min-width-px-235 pr-0">
                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                  {doc.Organization}
                                </h3>
                              </td>
                              <td className="table-y-middle py-7 min-width-px-170 pr-0">
                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                  {new Date(doc.created.seconds * 1000 + doc.created.nanoseconds / 1000000).toDateString()}
                                </h3>
                              </td>
                              <td className="table-y-middle py-7 min-width-px-235 pr-0">
                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                  {doc.status}
                                </h3>
                              </td>
                              {
                                (doc.status === "applied") &&
                                <td className="table-y-middle py-7 min-width-px-170 pr-0">
                                  <div className="">
                                    <a
                                      href="/#"
                                      className="font-size-3 font-weight-bold text-black-2 text-uppercase"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        toggleModalAffiliateApplicantVisibility();
                                        setAffiliatedata(doc);
                                      }}
                                    >
                                      View Application
                                    </a>
                                  </div>
                                </td>
                              }
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          modalCustomerApplicantVisible &&
          <ModalCustomerApplicant customerdata={customerdata} show={modalCustomerApplicantVisible} setModalCustomerApplicantVisible={setModalCustomerApplicantVisible}></ModalCustomerApplicant>
        }
        {
          modalAffiliateApplicantVisible &&
          <ModalAffiliateApplicant affiliatedata={affiliatedata} show={modalAffiliateApplicantVisible} setModalAffiliateApplicantVisible={setModalAffiliateApplicantVisible}></ModalAffiliateApplicant>
        }
      </PageWrapper>
    </>
  );
};
export default DashboardAdmin;
